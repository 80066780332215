import React, { useState, useEffect, useContext } from "react";

import {
	Paper,
	Container,
	Button,
	Grid,
	makeStyles,
	TextField,
	Typography,
	Snackbar,
} from "@material-ui/core";
import { StudentContext } from "../../../context/StudentContext";
import { patchStudProfile } from "../../../API/PATCH";
import { Alert } from "@material-ui/lab";
import StudentNavbar from "../../../components/Student/StudentNavbar/StudentNavbar";
import { fetchStudentProfile } from "../../../API/GET";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: "150px",
		height: "150px",
	},
	contPaper: {
		width: "80%",
		borderRadius: "20px",
		marginTop: "10px",
		paddingBottom: "40px",
		background: "#252D3A",
	},
	input: {
		width: "100%",
		color: "#fff",
	},
}));

const StudProfile = () => {
	const { studentProfile, setStudentDetails } = useContext(StudentContext);
	const history = useHistory();
	const classes = useStyles();

	const [error, setError] = useState(null);
	const [studData, setStudData] = useState(studentProfile.student);

	const [disabled, setDisabled] = useState(true);

	const [profileSuccess, setProfileSuccess] = useState(false);

	const [errorContact, setErrorContact] = useState(false);
	const [errorReg, setErrorReg] = useState(false);

	const handleProfileChange = (e) => {
		setStudData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const validate = () => {
		const regex = /^(\+?\d{1,3}[- ]?)?\d{10}$/;
		if (
			!studData.mobileNumber ||
			String(studData.mobileNumber).trim() === "" ||
			!regex.test(studData.mobileNumber)
		) {
			console.log(
				!studData.mobileNumber,
				String(studData.mobileNumber).trim(),
				!regex.test(studData.mobileNumber)
			);
			setErrorContact(true);
			return false;
		} else {
			setErrorContact(false);
		}
		const regregex = /^[0-9]{2}[A-Za-z]{3}[0-9]{4}$/;
		if (
			!studData.registrationNumber ||
			studData.registrationNumber.trim() === "" ||
			!regregex.test(studData.registrationNumber)
		) {
			setErrorReg(true);
			return false;
		} else {
			setErrorReg(false);
		}

		return true;
	};

	const updateProfile = async () => {
		if (validate()) {
			setDisabled(true);
			const token = localStorage.getItem("studentAuthToken");

			const res = await patchStudProfile(studData, token);

			if (res) {
				// getProfile(token);
				setProfileSuccess(true);
				const profile = await fetchStudentProfile(token);

				if (profile) {
					setStudentDetails(profile);
				}
			}
			history.push("/student/dashboard");
			setDisabled(false);
		}
	};

	useEffect(() => {
		if (JSON.stringify(studData) === JSON.stringify(studentProfile.club)) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}, [studData, studentProfile]);

	if (error) {
		return <div>Error: {error.message}</div>;
	} else if (studData) {
		return (
			<>
				<StudentNavbar location="Profile" />
				<div className="cont-colour" style={{ background: "#081220" }}>
					{/* <form onSubmit={updateProfile}> */}
					<Container
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "calc(100vh - 64px)",
							background: "#081220",
						}}
						className="profile-section-container"
					>
						<Paper elevation={3} className={classes.contPaper}>
							<Container>
								<Grid container spacing={7}>
									<Grid
										container
										style={{ marginTop: "40px" }}
										justify="center"
									>
										<Typography
											gutterBottom
											variant="h4"
											style={{
												fontFamily: "Source Sans Pro",
												fontWeight: "600",
												color: "#fff",
											}}
										>
											Profile Page
										</Typography>
									</Grid>
									<Grid item container xs={12}>
										<div
											style={{
												width: "100%",
												textAlign: "center",
												marginBottom: "30px",
												color: "#fff",
											}}
										>
											<Typography
												variant="body1"
												// className="light-text"
												color="#fff"
											>
												Not filling every field of the
												profile may result in
												disqualification
											</Typography>
										</div>
										{/* <form style={{ width: "100%" }}> */}
										<Grid container spacing={3}>
											<Grid item xs={6}>
												<TextField
													name="name"
													className={classes.input}
													label="Name"
													variant="outlined"
													value={studData.name}
													onChange={
														handleProfileChange
													}
													style={{
														color: "#fff",
													}}
													disabled
												/>
											</Grid>

											<Grid item xs={6}>
												<TextField
													name="branch"
													className={classes.input}
													label="Branch"
													variant="outlined"
													value={studData.branch}
													onChange={
														handleProfileChange
													}
													style={{ color: "fff" }}
												/>
											</Grid>

											<Grid item xs={6}>
												<TextField
													required
													name="mobileNumber"
													className={classes.input}
													label="Contact Number"
													color="#fff"
													variant="outlined"
													value={
														studData.mobileNumber
													}
													onChange={
														handleProfileChange
													}
													error={errorContact}
													helperText={
														errorContact
															? "Invalid number"
															: ""
													}
												/>
											</Grid>
											<Grid item xs={6}>
												<TextField
													name="registrationNumber"
													className={classes.input}
													label="VIT Registration Number"
													variant="outlined"
													color="#fff"
													background="#fff"
													value={
														studData.registrationNumber
													}
													error={errorReg}
													helperText={
														errorReg
															? "Invalid Registration number"
															: ""
													}
													onChange={
														handleProfileChange
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextField
													name="bio"
													className={classes.input}
													label="About You"
													multiline
													rows={8}
													color="#fff"
													variant="outlined"
													value={studData.bio}
													onChange={
														handleProfileChange
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<Button
													type="submit"
													variant="contained"
													color="primary"
													onClick={updateProfile}
													disabled={disabled}
												>
													Save Changes
												</Button>
											</Grid>
										</Grid>
										{/* </form> */}
									</Grid>
								</Grid>
							</Container>
						</Paper>

						<Snackbar
							autoHideDuration={4000}
							onClose={() => setProfileSuccess(false)}
							open={profileSuccess}
						>
							<Alert
								severity="success"
								variant="filled"
								onClose={() => setProfileSuccess(false)}
							>
								Your profile was successfully updated!
							</Alert>
						</Snackbar>
					</Container>
					{/* </form> */}
				</div>
			</>
		);
	} else {
		return <div></div>;
	}
};

export default StudProfile;
