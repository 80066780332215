import {
  AppBar,
  Button,
  Container,
  Grid,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import ChromeDinoGame from "react-chrome-dino";
import { Link } from "react-router-dom";
import BG from "../landing.png";
import "./CCProfile.css";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "150px",
    height: "150px",
    marginTop: "60px",
  },
  contPaper: {
    width: "100%",
    borderRadius: "20px",
    paddingBottom: "40px",
  },
  website: {
    "&:hf": {
      cursor: "pointer",
    },
  },
}));

const CCProfile = (props) => {
  const classes = useStyles();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [date, setDate] = useState(new Date("Dec 25 2020 08:00:00"));

  const timerRenderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div>
        <Typography
          variant="h5"
          style={{
            flex: 1,
            color: "#FFBC27",
            fontWeight: "bold",
            paddingBottom: "20px",
            fontfamily: "8BIT WONDER",
          }}
        >
          Test Begins In
        </Typography>
        <Typography
          variant="h3"
          style={{
            flex: 1,
            color: "#FFBC27",
            fontWeight: "bold",
            paddingBottom: "40px",
          }}
        >
          {days} d {hours} h {minutes} m {seconds} s
        </Typography>
      </div>
    );
  };

  const fetchProfile = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/club/details/username?username=codechefvit`;

    try {
      await Axios.get(url).then((res) => {
        setData(res.data.club);

        if (res.data.club === null) {
          setError(true);
        }
      });
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  };

  const applyForCC = () => {
    window.open(
      "https://cet-portal.codechefvit.com/student/club/61f437c4171218476b3de529"
    );
  };

  useEffect(() => {
    fetchProfile();
    console.log(date.toLocaleString());
  }, []);

  // if (loading) {
  // 	return <Loading />;
  // } else if (error) {
  // 	return <ErrorPage />;
  // }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${BG})`,
        backgroundColor: "black",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
      className="org-profile-container profile-section-container"
    >
      <div className={classes.contPaper}>
        <AppBar
          className="navbar"
          style={{
            backgroundColor: "rgba(124, 207, 255, 0.15)",
            zIndex: "1400",
            position: "relative",
          }}
          elevation={2}
        >
          <Toolbar>
            <Link to={`/student/dashboard`} style={{ marginRight: "20px" }}>
              <img src="/assets/Group2.png" alt="logo" width="65px" />
            </Link>
            <Typography
              variant="h5"
              style={{
                flex: 1,
                color: "#FFF",
                fontWeight: "bold",
              }}
            >
              Dashboard
            </Typography>
            <Button
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                border: "1px solid #FFF",
                color: "#FFF",
              }}
              href="/"
            >
              LogOut
            </Button>
          </Toolbar>
        </AppBar>
        <Container>
          <Grid container spacing={7}>
            <Grid container justify="center">
              {/* <Avatar
								alt="Club logo"
								src={
									data.clubAvatar
										? data.clubAvatar
										: "/assets/avatar.jpeg"
								}
								className={classes.avatar}
							/> */}
            </Grid>
            <Grid container style={{ marginTop: "60px" }} justify="center">
              <img
                src="/assets/recruiting-now.svg"
                alt="Recruiting Now"
                height={300}
              />
            </Grid>
            <Grid container style={{ marginTop: "60px" }} justify="center">
            <form onSubmit={applyForCC}>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                marginTop: "-1%",
              }}
            >
              <input
                type="image"
                name="submit"
                alt="submit"
                src="/assets/applynow.png"
                alt="domain2"
                width="200px"
              />
            </div>
          </form>
            </Grid>
           
            <Grid item container xs={12}>
              <form style={{ width: "100%" }}>
                <Grid container spacing={7}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{ marginRight: "20%" }}
                  >
                    {/* <Avatar
								alt="Club Logo"
								src={
									data.clubAvatar
										? data.clubAvatar
										: "/assets/avatar.jpeg"
								}
								className={classes.avatar}
							/> */}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      marginTop: "30px",
                      marginLeft: "7.5%",
                      marginBottom: "5%",
                    }}
                  >
                    <form style={{ width: "100%" }}>
                      <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={6}>
                          <a className="des">
                            <span className="tex">Name:&nbsp;&nbsp;</span>
                            CodeChef-VIT
                            {/* {data.name}{" "} */}
                          </a>
                        </Grid>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ marginTop: "24px" }}>
                            <a className="tex">Description:</a>
                            <div
                              name="bio"
                              label="Description"
                              variant="outlined"
                              className="des"
                              style={{ marginTop: "20px", marginRight: "7.5%" }}
                            >
                              {/* {data.bio}{" "} */}
                              CodeChef-VIT is a non-commercial organization
                              built with the aim to equip students with a
                              platform to enable them to identify and grow as
                              programmers and developers. With several technical
                              domains, it is home to a large community of
                              enthusiastic students, blooming not just in
                              technical skills, but in multiple and diverse
                              avenues. The CodeChef-VIT family extends a warm
                              welcome towards anyone who loves the vast field of
                              technology in all its domains.
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ marginTop: "12px" }}>
                            <a
                              href="https://www.codechefvit.com/"
                              className="org-website-link"
                              rel="noreferrer"
                              target="_blank"
                            >
                              <a className="des">
                                <span className="tex">
                                  Organisation Link:&nbsp;&nbsp;
                                </span>
                                <span>www.codechefvit.com</span>{" "}
                              </a>
                            </a>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div>
                            <a className="des">
                              <span className="tex">
                                Contact Number:&nbsp;&nbsp;
                              </span>
                              {/* {data.mobileNumber}{" "} */}
                              +91 70818 27226
                            </a>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
        <Grid
          style={{
            marginBottom: "20px",
          }}
        >
          <form onSubmit={applyForCC}>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src="/assets/dom1.png" alt="domain" width="225px" />
              <a
                className="tex"
                style={{
                  color: "#B8D8F8",
                  marginRight: "7.5%",
                  marginLeft: "7.5%",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "20px",
                  lineHeight: "20px",
                  letterSpacing: "0.05rem",
                }}
              >
                Domains
              </a>

              <img src="/assets/dom2.png" alt="domain2" width="225px" />
            </div>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                marginTop: "-1%",
              }}
            >
              <input
                type="image"
                name="submit"
                alt="submit"
                src="/assets/applynow.png"
                alt="domain2"
                width="200px"
              />
            </div>
          </form>

          <ChromeDinoGame />
        </Grid>

        <Grid>
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              color: "#FFFFFF",
            }}
          >
            Made with 💙 by &nbsp;
            <a
              href="https://www.codechefvit.com"
              style={{ color: "#FFF" }}
              target="_blank"
              rel="noreferrer"
            >
              CodeChef-VIT
            </a>
          </p>
        </Grid>
      </div>
    </div>
  );
};

export default CCProfile;
